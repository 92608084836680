.header{
  padding-top: rem-calc(5);  
  padding-bottom: rem-calc(5);
  background-color: white;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  font-family: 'soleil', sans-serif;

  @include breakpoint(medium){
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
  }
}
.header-logo{
  height: 60px;
  
}

.header.header-home{
   padding-bottom: rem-calc(5);


  .header-logo{

    height: 50px;

    @include breakpoint(medium){
      margin-bottom: rem-calc(8);
      height: 70px;
    }
    
  }

 
  nav{
    margin-top: rem-calc(5);
   
    border-top: 1px solid $light-gray;

    @include breakpoint(medium){

      padding-top: rem-calc(5);
    }
  }

}
.case-study-header-title{
  font-size: rem-calc(25);
  font-weight: 600;
}

 @include breakpoint(small only){
  .menu a{
    padding: 0.6rem 0.48rem;
  } 
}

.menu a{
  text-transform: uppercase;
  font-family: 'soleil', sans-serif;
  font-size: rem-calc(14);
  font-weight: 500;
  color: $black;
}

.hero{

  background-image:linear-gradient(90deg, rgba(48,51,50,0.7) 0%, rgba(48,51,50,0.7) 100%), url('../img/floodlights-750.jpg');
  @include breakpoint(medium){
   background-image:linear-gradient(90deg, rgba(48,51,50,0.7) 0%, rgba(48,51,50,0.7) 100%), url('../img/floodlights.jpg');
  }
 
  background-position: center;
  background-size: cover;
  color: white;
  border-bottom: 10px solid $black;
  //margin-bottom: rem-calc(100);

  img{
     padding: 0 rem-calc(30);
    max-height: 200px;
  }
 .subtitle{
    font-size: rem-calc(31);
    font-family: 'soleil';
    font-weight: 300;
    text-transform: lowercase;
  }
}

.hero-content{
  height: calc( 100vh - 150px);
}