
.site-footer{
  background-color: $black;
  border-top: 2px solid $primary-color;
  color: white;
  padding-top: rem-calc(100);
  text-align: center;
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(40);
  border-top: 1px solid lighten($black, 8%);
}

.single-case_studies{
  .footer{
    margin-top: rem-calc(140);
  }
}

ul{
  font-size: rem-calc(20);
  list-style-type: none;
  margin-left: 0;
}
.atol-section{
  border-top: 1px solid lighten($black, 8%);
  margin-top: rem-calc(30);
  padding-top: rem-calc(30);
}
.footer{
  border-top: 2px solid $primary-color;
}