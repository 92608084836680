html {
  scroll-behavior: smooth;
 
}
html, body{
  width: 100%;
  overflow-x: hidden;
}

a.button{
  text-transform: uppercase;
}

.subtitle{
  font-size: rem-calc(40);
  font-weight: normal;
  font-family: 'coranto-2';
}

// hr {
//     overflow: visible; /* For IE */
//     padding: 0;
//     border: none;
//     border-top: medium double $primary-color;
//     color: $primary-color;
//     text-align: center;
// }


p{

  font-size: rem-calc(16);
  @include breakpoint(medium){
    font-size: rem-calc(19);
  }
  
}

blockquote{
  font-size: rem-calc(19);
}

cite{
  margin-top: rem-calc(10);
  font-size: rem-calc(21);
  font-family: $header-font-family;
  font-style: normal;
}

.ball-spacer{
  text-align: center;
  position: relative;
  background-image: url('../img/ball.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 30px;
  height: 30px;
  width: 100%;
  margin-top: rem-calc(80);
  margin-bottom: rem-calc(0);

  &:before{
    content: '';
    position: absolute;
    top: 50%;
    display: block;
    width: 45%;
    height: 2px;
    background-color: $primary-color;
  }
  &:after{
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 45%;
    height: 2px;
    background-color: $primary-color;
  }

  &.small{
  margin-top: 5px;
  margin-bottom: 8px;

  &:before{
    content: '';
    position: absolute;
    top: 50%;
    left: 32%;
    display: block;
    width: 15%;
    height: 2px;
    background-color: $primary-color;
  }
  &:after{
    content: '';
    position: absolute;
    top: 50%;
    right: 32%;
    display: block;
    width: 15%;
    height: 2px;
    background-color: $primary-color;
  }
  }
}

// .ball-spacer{
//   text-align: center;
//   position: relative;
//   background-image: url('../img/ball.svg');
//   background-position: 50%;
//   background-repeat: no-repeat;
//   background-size: 30px;
//   height: 30px;
//   width: 100%;
//   margin-top: rem-calc(80);
//   margin-bottom: rem-calc(0);

//   &:before{
//     content: '';
//     position: absolute;
//     top: 50%;
//     display: block;
//     width: 45%;
//     height: 2px;
//     background-color: $primary-color;
//   }
//   &:after{
//     content: '';
//     position: absolute;
//     top: 50%;
//     right: 0;
//     display: block;
//     width: 45%;
//     height: 2px;
//     background-color: $primary-color;
//   }

//   &.small{
//   margin-top: 5px;
//   margin-bottom: 8px;

//   &:before{
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 32%;
//     display: block;
//     width: 15%;
//     height: 2px;
//     background-color: $primary-color;
//   }
//   &:after{
//     content: '';
//     position: absolute;
//     top: 50%;
//     right: 32%;
//     display: block;
//     width: 15%;
//     height: 2px;
//     background-color: $primary-color;
//   }
//   }
// }