.content-block{
  padding-top: rem-calc(100);

  @include breakpoint(medium){
    padding-top: rem-calc(140);
  }
  
}

.narrow{
    max-width: rem-calc(700);
}

.about-us-row:first-of-type{
  margin-bottom: rem-calc(80);

}

.alt-bg-section{
  margin-top: rem-calc(140);
  padding-bottom: rem-calc(100);
  background-color: $black;
  color: white;
}

.alt-bg-section.footer{
  margin-top: 0;
}
.testimonial{
  background-image:linear-gradient(90deg, rgba(48,51,50,0.7) 0%, rgba(48,51,50,1) 80%), url('../img/wembley-sign.jpg');
  background-size: cover;
  background-position: center;
  padding-top: rem-calc(140);
  padding-bottom: rem-calc(140);
}

.case-studies{
  background-image:linear-gradient(90deg, rgba(48,51,50,1) 20%, rgba(48,51,50,0.7) 100%), url('../img/chairs-bg.jpg');
  background-size: cover;
  background-position: center;
}
.case-studies, .proposition{

  p{
    font-size: rem-calc(19);
    margin-bottom: rem-calc(30);
  }
  img {
    outline: 3px solid white;
    outline-offset: -12px;
    
  }
  .image-container{
    margin-left: rem-calc(80);
    text-align: center;
  }
}

.proposition{
  img{
    width: 300px;
  };
}


.icons{

  .image-container{
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);
  }

  img{
    max-height: 200px;
  }
  
}

